var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", attrs: { disabled: _vm.disabled } },
    [
      !_vm.hideInput
        ? _c("form-element-group", {
            scopedSlots: _vm._u(
              [
                {
                  key: "col-1",
                  fn: function() {
                    return [
                      _c("v-slide-x-reverse-transition", [
                        _vm.formOfId === _vm.items[0].slug
                          ? _c(
                              "div",
                              [
                                _c("form-text-field", {
                                  key: "1",
                                  ref: "field",
                                  attrs: {
                                    label: _vm.$t(
                                      "applications.social-security"
                                    ),
                                    value: _vm.profileData.ssn || "",
                                    icon: "info",
                                    placeholder: _vm.$t(
                                      "applications.enter-ssn"
                                    ),
                                    mask: "###-##-####",
                                    name: "ssn",
                                    rules: [
                                      function(v) {
                                        return (
                                          (!!v && _vm.hasSSN) ||
                                          _vm.$t("applications.ssn-required")
                                        )
                                      },
                                      function(v) {
                                        return (
                                          (/^\d{3}-\d{2}-\d{4}$/.test(v) &&
                                            _vm.hasSSN) ||
                                          _vm.$t("applications.ssn-invalid")
                                        )
                                      }
                                    ],
                                    disabled: !_vm.hasSSN,
                                    required: _vm.hasSSN
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateProfile("ssn", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c("v-slide-x-transition", [
                        _vm.formOfId === _vm.items[1].slug
                          ? _c(
                              "div",
                              [
                                _c("form-text-field", {
                                  key: "2",
                                  ref: "last_name",
                                  attrs: {
                                    label: _vm.$t("applications.last-name"),
                                    name: "last_name",
                                    required: "",
                                    value: _vm.profileData.last_name || "",
                                    rules: [
                                      function(v) {
                                        return (
                                          !!v ||
                                          _vm.$t(
                                            "applications.last-name-required"
                                          )
                                        )
                                      }
                                    ],
                                    icon: "person",
                                    placeholder: _vm.$t(
                                      "applications.enter-last-name"
                                    )
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateProfile(
                                        "last_name",
                                        $event
                                      )
                                    }
                                  }
                                }),
                                _c("form-text-field", {
                                  key: "3",
                                  ref: "field",
                                  attrs: {
                                    label: _vm.$t("applications.dob"),
                                    required: "",
                                    placeholder: "mm/dd/yyyy",
                                    mask: "##/##/####",
                                    icon: "calendar_today",
                                    name: "birthdate",
                                    value: _vm.profileData.birthdate || "",
                                    rules: [
                                      function(v) {
                                        return (
                                          !!v ||
                                          _vm.$t("applications.dob-required")
                                        )
                                      },
                                      function(v) {
                                        return (
                                          /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                            v
                                          ) ||
                                          _vm.$t("applications.dob-invalid")
                                        )
                                      }
                                    ]
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateProfile(
                                        "birthdate",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "col-2",
                  fn: function() {
                    return [
                      _c(
                        "form-element",
                        { attrs: { label: _vm.$t("applications.form-of-id") } },
                        [
                          _c("v-select", {
                            key: _vm.selectKey,
                            staticClass: "mt-2",
                            attrs: {
                              solo: "",
                              items: _vm.items,
                              "item-text": "title",
                              "item-value": "slug"
                            },
                            model: {
                              value: _vm.formOfId,
                              callback: function($$v) {
                                _vm.formOfId = $$v
                              },
                              expression: "formOfId"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              931413189
            )
          })
        : _vm._e(),
      !_vm.hideActions && (!_vm.hideInput || _vm.clearable)
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "12" } },
                [
                  _vm.clearable
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "warning darken-2",
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            large: _vm.$vuetify.breakpoint.smAndUp
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleClear($event)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")]), _vm._v("Clear ")],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  !_vm.hideInput
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text v-btn__submit",
                          attrs: {
                            loading: _vm.loading,
                            large: _vm.$vuetify.breakpoint.smAndUp,
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            color: "secondary"
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleSubmit($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: _vm.$vuetify.breakpoint.smAndDown
                              }
                            },
                            [_vm._v("mdi-magnify")]
                          ),
                          _vm._v(
                            _vm._s(
                              _vm.$t("applications.search-screening-history")
                            ) + " "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "3000", centered: "", color: "error darken-2" },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-start" },
            [
              _c("p", { staticClass: "py-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("applications.please-correct-errors")) +
                    " "
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }