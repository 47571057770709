<template>
    <v-form ref="form" :disabled="disabled">
        <!-- Social Security  -->
        <form-element-group v-if="!hideInput">
            <template v-slot:col-1>
                <v-slide-x-reverse-transition>
                    <div v-if="formOfId === items[0].slug">
                        <form-text-field
                            ref="field"
                            key="1"
                            :label="$t('applications.social-security')"
                            :value="profileData.ssn || ''"
                            icon="info"
                            :placeholder="$t('applications.enter-ssn')"
                            mask="###-##-####"
                            name="ssn"
                            :rules="[
                                (v) =>
                                    (!!v && hasSSN) ||
                                    $t('applications.ssn-required'),
                                (v) =>
                                    (/^\d{3}-\d{2}-\d{4}$/.test(v) && hasSSN) ||
                                    $t('applications.ssn-invalid'),
                            ]"
                            :disabled="!hasSSN"
                            :required="hasSSN"
                            @input="updateProfile('ssn', $event)"
                        ></form-text-field>
                    </div>
                </v-slide-x-reverse-transition>
                <v-slide-x-transition>
                    <div v-if="formOfId === items[1].slug">
                        <form-text-field
                            key="2"
                            ref="last_name"
                            :label="$t('applications.last-name')"
                            name="last_name"
                            required
                            :value="profileData.last_name || ''"
                            :rules="[
                                (v) =>
                                    !!v ||
                                    $t('applications.last-name-required'),
                            ]"
                            icon="person"
                            :placeholder="$t('applications.enter-last-name')"
                            @input="updateProfile('last_name', $event)"
                        ></form-text-field>
                        <form-text-field
                            key="3"
                            ref="field"
                            :label="$t('applications.dob')"
                            required
                            placeholder="mm/dd/yyyy"
                            mask="##/##/####"
                            icon="calendar_today"
                            name="birthdate"
                            :value="profileData.birthdate || ''"
                            :rules="[
                                (v) => !!v || $t('applications.dob-required'),
                                (v) =>
                                    /^((((0[13578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/.test(
                                        v
                                    ) || $t('applications.dob-invalid'),
                            ]"
                            @input="updateProfile('birthdate', $event)"
                        ></form-text-field>
                    </div>
                </v-slide-x-transition>
            </template>
            <template v-slot:col-2>
                <form-element :label="$t('applications.form-of-id')">
                    <v-select
                        :key="selectKey"
                        class="mt-2"
                        solo
                        :items="items"
                        item-text="title"
                        item-value="slug"
                        v-model="formOfId"
                    ></v-select>
                </form-element>
            </template>
        </form-element-group>
        <v-row v-if="!hideActions && (!hideInput || clearable)">
            <v-col cols="12" class="d-flex">
                <v-btn
                    @click.prevent="handleClear"
                    v-if="clearable"
                    color="warning darken-2"
                    :small="$vuetify.breakpoint.smAndDown"
                    :large="$vuetify.breakpoint.smAndUp"
                >
                    <v-icon>mdi-close</v-icon>Clear
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!hideInput"
                    :loading="loading"
                    :large="$vuetify.breakpoint.smAndUp"
                    :small="$vuetify.breakpoint.smAndDown"
                    color="secondary"
                    class="white--text v-btn__submit"
                    @click.prevent="handleSubmit"
                >
                    <v-icon :small="$vuetify.breakpoint.smAndDown"
                        >mdi-magnify</v-icon
                    >{{ $t("applications.search-screening-history") }}
                </v-btn>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="snackbar"
            timeout="3000"
            centered
            color="error darken-2"
        >
            <div class="d-flex align-start">
                <p class="py-1">
                    {{ $t("applications.please-correct-errors") }}
                </p>
                <v-spacer></v-spacer>
                <v-btn small text @click="snackbar = false"
                    ><v-icon small>mdi-close</v-icon></v-btn
                >
            </div>
        </v-snackbar>
    </v-form>
</template>
<script>
export default {
    name: "verify-screening-form",
    components: {},
    props: {
        profile: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideInput: {
            type: Boolean,
            required: false,
            default: false,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            profileData: this.$_.pick(this.profile, [
                "last_name",
                "ssn",
                "birthdate",
            ]),
            formOfId: "",
            selectKey: this.$uuid.v4(),
            items: [
                {
                    slug: "social-security",
                    title: this.$t("applications.social-security"),
                },
                {
                    slug: "no-identification-number",
                    title: this.$t("applications.no-identification-number"),
                },
            ],
            snackbar: false,
        };
    },
    computed: {
        hasSSN() {
            return this.formOfId === "social-security";
        },
    },
    watch: {},
    created() {},
    mounted() {
        this.initialize();
    },
    updated() {},
    detroyed() {},
    methods: {
        updateProfile(key, value) {
            this.profileData = Object.assign({}, this.profileData, {
                [key]: key === "ssn" ? value.replace(/-/g, "") : value,
            });
            // this.$emit("update:profile", this.profileData);
        },
        handleSubmit() {
            if (this.validate()) {
                this.$emit("search", this.profileData, this.formOfId);
            } else {
                this.snackbar = true;
            }
        },
        handleClear() {
            this.initialize(true);
            this.$emit("clear", this.profileData);
        },
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        initialize(data = false) {
            this.formOfId = this.items[0].slug;
            this.selectKey = this.$uuid.v4();
            if (data) {
                this.profileData = {
                    last_name: "",
                    ssn: "",
                    birthdate: "",
                };
            }
        },
    },
};
</script>

<style scoped>
</style>